<!--Copyright 2024 Sadiant Inc. All Rights Reserved. This software is subject to a license agreement. Unauthorized or unlicensed use is prohibited.-->
<!-- <div class="sadiant-banner">
  <div class="banner-title">
    Need a clinician for your employee temperature screenings? Sadiant can help.
  </div>
  <div class="banner-body">
    Reach out to us at <a href="mailto:hello@sadianthealth.com">hello@sadianthealth.com</a> to learn more.
  </div>
</div> -->
<div class="container-fluid px-3">
  <div class="flex lg:my-4 -mx-3">
    <div class="w-full lg:w-2 xl:w-3 sadiant-logo-wrapper my-auto px-3">
      <div [routerLink]="'/'"
           class="sadiant-logo"></div>
    </div>
    <div class="w-full lg:w-10 xl:w-9 my-auto px-3">
      <ul class="menu-wrapper"
          *ngIf="isHamburgerOpen"
          [@menuAnimation]>
        <li class="about-us"
            [routerLink]="'/about-us'"
            [routerLinkActive]="'active'">
          About us
          <!-- <ul class="leadership-wrapper">
            <li class="leadership"
                (click)="stopParentRouterLink($event)"
                [routerLink]="'/about-us/leadership'"
                [routerLinkActive]="'active'">
              Leadership
            </li>
          </ul> -->
        </li>
        <li [routerLink]="'/facilities'"
            [routerLinkActive]="'active'">
          Facilities
        </li>
        <li [routerLink]="'/clinicians'"
            [routerLinkActive]="'active'">
          Clinicians
        </li>
        <li [routerLink]="'/news'"
            [routerLinkActive]="'active'">
          News
        </li>
        <li [routerLink]="'/contact'"
            [routerLinkActive]="'active'">
          Contact
        </li>
        <li class="sign-in"
            tabIndex="0">
          Sign in
          <ul class="sign-in-wrapper">
            <li class="facilities">
              <a [href]="clientPortalUrl">Facilities</a>
            </li>
            <li class="clinicians">
              <a [href]="clinicianPortalUrl">Clinicians</a>
            </li>
          </ul>
        </li>
        <li [routerLink]="'/sign-up'"
            class="sign-up">
          Sign up
        </li>
      </ul>
      <div class="hamburger-wrapper">
        <button class="hamburger hamburger--collapse"
                (click)="openCloseHamburger()"
                [ngClass]="{ 'is-active': isHamburgerOpen }"
                type="button"
                aria-label="Menu"
                aria-controls="navigation">
          <span class="hamburger-box">
            <span class="hamburger-inner"></span>
          </span>
        </button>
      </div>
    </div>
  </div>
</div>
